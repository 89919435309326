<template>
  <div>
    <a-layout >
      <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
        <Header />
      </a-layout-header>
      <a-layout>
        <Sidebar />
        <!-- <TableLoader v-if="loader"/> -->
        <a-layout-content >
          <a-row>
            <a-col :xl="4" :lg="4">
              <h2 class="pageTittle">{{$t('patientSummary.patientSummary')}}</h2>
            </a-col>
            <a-col :xl="7" :lg="7">
              <a-space v-if="patientDetails?.email != '' || patientDetails?.phoneNumber != ''">
                <a-button type="primary" @click="sendMessage" v-if="arrayToObjact(screensPermissions, 109) && conversationWithPatient != null">
                  <template #icon><MessageOutlined /></template>
                  In App Message
                </a-button>
                <SendMessage v-if="arrayToObjact(screensPermissions, 503) && patientDetails" :patientId="{id:paramsId,name:patientDetails?.fullName,contactType:patientDetails?.contactType[0]}"></SendMessage>
                <a-button v-if="arrayToObjact(screensPermissions, 107)" type="primary" @click="startCall" :loading="iconLoading">
                  <template #icon><VideoCameraOutlined /></template>
                  Call
                </a-button>
              </a-space>
            </a-col>
            <a-col :xl="7" :lg="7">
              <div class="pageTittle patientSummaryFilters">
                <div class="filter">
                  <a-button @click="showButton(1) ; actionTrack(paramsId,285,'patient')" :class="button == 1 ? 'active' : ''" v-if="arrayToObjact(screensPermissions, 285)">Timeline</a-button>
                  <a-button @click="showButton(2) ; actionTrack(paramsId,286,'patient')" :class="button == 2 ? 'active' : ''" v-if="arrayToObjact(screensPermissions, 286)">Care Plan</a-button>
                  <a-button @click="showButton(3) ; actionTrack(paramsId,287,'patient')" :class="button == 3 ? 'active' : ''" v-if="arrayToObjact(screensPermissions, 287)">Patient Vitals</a-button>
                  <a-button @click="showButton(4) ; actionTrack(paramsId,287,'patient')" :class="button == 4 ? 'active' : ''" v-if="arrayToObjact(screensPermissions, 287)">Escalation</a-button>
                </div>
              </div>
            </a-col>
            <a-col :xl="6" :lg="6" v-if="arrayToObjact(screensPermissions, 472)">
                <div class="timer" @click="actionTrack(paramsId,288,'patient')" >
                <h3>{{$t('patientSummary.currentSession')}} : {{formattedElapsedTime}}</h3>
                <a-button v-if="(showStartTimer && !showPauseTimer)" class="primaryBtn" @click="startTimer">{{$t('patientSummary.startTimer')}}</a-button>
                <a-button v-if="(!showResumeTimer && showPauseTimer)" class="primaryBtn" @click="pauseTimer">{{$t('patientSummary.pauseTimer')}}</a-button>
                <a-button v-if="(showResumeTimer && !showStartTimer)"  class="primaryBtn" @click="startTimer">{{$t('patientSummary.resumeTimer')}}</a-button>
                <a-button v-if="!showStartTimer" class="primaryBtn" id="timer3"  @click="stopTimer">{{$t('patientSummary.stopTimer')}}</a-button>
              </div>
            </a-col>
            <div id="timer" v-if="showDiv"></div>
          </a-row>
          <a-row>
            <a-col :sm="24">
              <CriticalNotes v-if="patientCriticalNotes && patientCriticalNotes.length > 0" />
            </a-col>
            <a-col :sm="24" :xs="24">
              <div>
                <PatientSummaryContent :patientId="paramsId" :buttonId="button"/>
                <!-- <TableLoader /> -->
              </div>
            </a-col>
          </a-row>
        </a-layout-content>
        <ChatWithPatientInformation v-model:visible="chatWithPatientInfoVisible" v-if="chatWithPatientInfoVisible && conversation" :communication="conversation" :conversationId="conversationId" :idPatient="receiverId" :timer="formattedElapsedTime" @pauseTimer="pauseTimer" @stopTimer="stopTimer" @startTimer="startTimer" />
      </a-layout>
    </a-layout>

    <TimelineLoader/>
    <VideoCallVue  v-if="videoModal && conferenceId" v-model:visible="videoModal" :conferenceId ="conferenceId"/>
    <AuditTimeLog v-if="visible" v-model:visible="visible" @saveAuditTimeLog="handleOk($event)" :Id="selectedRow" :editData="auditTimeLog" :confirm="true"/>
  </div>
</template>

<script>
import Header from "@/components/layout/header/Header";
import Sidebar from "@/components/layout/sidebar/Sidebar";
import PatientSummaryContent from "@/components/patients/patientSummary/views/PatientSummaryContent";
import CriticalNotes from "@/components/patients/patientSummary/common/CriticalNotes";
import ChatWithPatientInformation from "@/components/modals/ChatWithPatientInformation";
import dayjs from "dayjs"; 
import { ref, computed,onBeforeMount, onUnmounted,reactive, onMounted, defineAsyncComponent, watchEffect} from "vue";
import { useStore } from 'vuex';
import { useRoute,useRouter  } from 'vue-router';
import TimelineLoader from "@/components/loader/TimelineLoader"
import AuditTimeLog from "@/components/time-log-approval/AuditTimeLogs.vue";
import {
  getSeconds,
  actionTrack,
  enCodeStringVideoURL,
  arrayToObjact
} from '@/commonMethods/commonMethod';
import {
  MessageOutlined,
  VideoCameraOutlined
} from "@ant-design/icons-vue";
const value = ref(dayjs("12:08", "HH:mm"));
function clearEvent(event){
  event.returnValue = '';
}
    
export default {
  components: {
    MessageOutlined,
    VideoCameraOutlined,
    TimelineLoader,
    Header,
    Sidebar,
    PatientSummaryContent,
    CriticalNotes,
    ChatWithPatientInformation,
    AuditTimeLog,
    SendMessage:defineAsyncComponent(()=>import("@/components/communications/top/SendMessage")),
    VideoCallVue:defineAsyncComponent(()=>import('@/components/video-call/PatientVideoCall.vue'))
  },
  setup() {
    localStorage.removeItem('timeApprovalId')
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const patientUdid = route.params.udid
    const authUser =  JSON.parse(localStorage.getItem('auth'))
    const notevisible = ref(false);
    const devicevisible = ref(false);
    const documentvisible = ref(false);
    const addnotesvisible = ref(false);
    const timelogsvisible = ref(false);
    const bloodoxygenvisible = ref(false);
    const bloodglucosevisible = ref(false);
    const chatWithPatientInfoVisible = ref(false);
    const videoModal = ref(false)
    const isEditTimeLog = ref(false);
    const loader= ref(true)
    const visible =ref()
    const selectedRow = ref([])
    const showDiv = ref(false)
    const auditTimeLog = reactive({
      timeAmount: "",
      typeId: "",
    });
    const timeApprovalType = computed(() => {
      return store.state.common.timeApprovalType
    })

    const pendingApprovalStatus = computed(() => {
      return store.state.common.pendingApprovalStatus
    })
    
    const iconLoading = ref(false)
    const onClose = (e) => {
      console.log(e, "I was closed.");
    };

    const showStartTimer = computed(() => {
      return store.state.common.showStartTimer
    });
    const showPauseTimer = computed(() => {
      return store.state.common.showPauseTimer
    });
    const showResumeTimer = computed(() => {
      return store.state.common.showResumeTimer
    });

    const patientDetails = computed(()=>{
      return store.state.patients.patientDetails
    })

    watchEffect(()=>{
      if(route.params.udid && route.name == "PatientSummary"){
        patientDetail();
      }
    })

    const receiverId = ref(null)
    const screensPermissions = store.getters.screensPermissions
    onMounted(() => {
      localStorage.removeItem('cancelButton')
      if(route.name == "PatientSummary") {
        arrayToObjact(screensPermissions.value,472) ?  startTimer() : ''
        patientDetail();
      }
    })

    function patientDetail(){ 
      store.dispatch('patientDetails', route.params.udid).then(() => {
          receiverId.value = patientDetails.value.user.data.id
          store.dispatch("program", patientUdid);
          store.commit("loadingTableStatus",true)
          loader.value = true
          store.dispatch('devices', route.params.udid)
           store.dispatch('activeCptCodes')
          store.dispatch('allPatientsList')
          store.dispatch('allStaffList')
          store.dispatch('patientFlagsList', {patientUdid:route.params.udid,catId:1});
          store.dispatch('patientFlagsList', {patientUdid:route.params.udid,catId:2});
          store.dispatch('patientCriticalNotes', route.params.udid);
          store.dispatch('responsiblePerson', route.params.udid);
          store.dispatch("patientCareCoordinatorsList", {
            patientUdid: route.params.udid,
            type: 1,
          });
          store.dispatch("patientCareCoordinatorsList", {
            patientUdid: route.params.udid,
            type: 0,
          });
          store.dispatch('patientConditions', route.params.udid);
          store.dispatch('emergencyContactsList', route.params.udid).then(()=>{
            store.commit("loadingTableStatus",false)
            loader.value = false
          })
          if(!showStartTimer.value && route.params.globalSearch) {
            elapsedTime.value = 0;
            store.commit('showStartTimer', true);
            clearInterval(timer.value);
            startTimer()
          }
          store.dispatch("conversationWithPatient", receiverId.value)
        })
    }

    function videoCall() {
      store.dispatch("appointmentCalls",{patientId:patientUdid})
    }

    const custom = ref(false);
    const current = ref(0);

    const handleOkcustom = (e) => {
      console.log(e);
      custom.value = false;
    };

    const next = () => {
      current.value++;
    };

    const prev = () => {
      current.value--;
    };

    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };
    const patientCriticalNotes = computed(() => {
      return store.state.patients.patientCriticalNotes
    })

    const button = ref(1);

    function showButton(value) {
      button.value = value;
    }
    
    // Countdown Timer
    const elapsedTime = ref(0)
    const timer = ref(undefined)
    
    const formattedElapsedTime = computed(() => {
      const date = new Date(null);
      date.setSeconds(elapsedTime.value / 1000);
      const utc = date.toUTCString();
      return utc.substr(utc.indexOf(":") - 2, 8);
    })
    const timerValue = ref(30000)
    
   

    function startTimer() {
      showDiv.value = true
      timer.value = setInterval(() => {
        elapsedTime.value += 1000;
        if((elapsedTime.value)%timerValue.value === 0) {
          const newFormattedElapsedTime = getSeconds(formattedElapsedTime.value)
          const timeApprovalId =  localStorage.getItem('timeApprovalId')
          if((timeApprovalId && timeApprovalId != null) && route.name == 'PatientSummary') {
            const data = {
              time: newFormattedElapsedTime,
              isAutomatic: true,
            }
            store.dispatch("updateTimeApproval", {
              timeApprovalId: timeApprovalId,
              data: data
            })
          }
          else {
            if(route.name == 'PatientSummary') {
              store.dispatch("timeApproval", {
                staff: authUser.user.staffUdid,
                patient: route.params.udid,
                time: newFormattedElapsedTime,
                type: timeApprovalType.value,
                status: pendingApprovalStatus.value,
                entityType: 'patient',
                referenceId: route.params.udid,
                isAutomatic: true
              })
            }
          }
        }
      }, 1000);
      store.commit('showPauseTimer', true);
      store.commit('showStartTimer', false);
      store.commit('showResumeTimer', false);
    }

    const stopTimer = () => {
      
      const seconds = getSeconds(formattedElapsedTime.value)
      const cancelButton = ref(localStorage.getItem('cancelButton'))
      auditTimeLog.timeAmount = seconds
     
      if(cancelButton.value != null) {
        store.commit("loadingTableStatus", true)
      }
      if(!showStartTimer.value) {
        store.commit('showStartTimer', true);
        store.commit('showPauseTimer', false);
        store.commit('showResumeTimer', false);
        isEditTimeLog.value = true;
        clearInterval(timer.value);
        elapsedTime.value = 0;

        const timeApprovalId =  localStorage.getItem('timeApprovalId')
        if((timeApprovalId && timeApprovalId != null)) {
           selectedRow.value= [timeApprovalId]
          const data = {
            time: seconds,
            isAutomatic: false,
          }
          store.dispatch("updateTimeApproval", {
            timeApprovalId: timeApprovalId,
            data: data
          }).then(() => {
             visible.value = true
            if(cancelButton.value) {
              router.push({
                path: cancelButton.value
              });
              localStorage.removeItem('cancelButton')
            }
            localStorage.removeItem('timeApprovalId')
            store.commit("loadingTableStatus", false)
          })
        }
        else {
          store.dispatch("timeApproval", {
            staff: authUser.user.staffUdid,
            patient: route.params.udid,
            time: seconds,
            type: timeApprovalType.value,
            status: pendingApprovalStatus.value,
            entityType: 'patient',
            referenceId: route.params.udid,
            isAutomatic: true
          }).then(() => {
            let timeId = localStorage.getItem('timeApprovalId')
            selectedRow.value= [timeId]
            visible.value = true
            if(cancelButton.value) {
              router.push({
                path: cancelButton.value
              });
              localStorage.removeItem('cancelButton')
            }
            localStorage.removeItem('timeApprovalId')
            store.commit("loadingTableStatus", false)
          })
        }
      }
    };

    const pauseTimer = () => {
      store.commit('showResumeTimer', true);
      store.commit('showPauseTimer', false);
      clearInterval(timer.value);
    };
  
    const handleClose = ({link=null,modal, value, cancelBtn}) => {
      if(modal == 'cancelButton') {
        if(link==true&& cancelBtn != null) {
          elapsedTime.value = 0;
          store.commit('showStartTimer', true);
          clearInterval(timer.value);
          router.push({
            path: cancelBtn
          });
        }
        else {
          if(!showPauseTimer.value) {
            startTimer()
          }
          store.commit('showStartTimer', false);
        }
      }
      else if(modal == 'closeTimeLogModal') {
        if(!showPauseTimer.value) {
          startTimer()
        }
        store.commit('showStartTimer', false);
      }
      else if(modal == 'addTimeLog') {
        elapsedTime.value = 0;
        store.commit('showStartTimer', true);
        clearInterval(timer.value);
        if(cancelBtn != null) {
          router.push({
            path: cancelBtn
          });
        }
      }
      if(value == undefined) {
        if(!showPauseTimer.value) {
          startTimer()
        }
        store.commit('showStartTimer', false);
      }
    };
    
    onBeforeMount(() => {
        window.addEventListener('beforeunload',clearEvent);
    })


    onUnmounted(() => {
      store.commit('showStartTimer', false);
      store.commit('showPauseTimer', true);
      store.commit('showResumeTimer', false);
      store.state.patients.tabvalue = []
      clearInterval(timer.value);
      localStorage.removeItem('timeApprovalId')
      window.removeEventListener('beforeunload', clearEvent); 
      store.state.patients.patientDetails = ''
      store.state.patients.patientDocuments = ''
      store.state.patients.patientTimeline = ''
      store.state.patients.patientConditions = []
      store.state.patients.patientReferralSource = ''
      store.state.patients.referralList = ''
      store.state.patients.familyMembersList = ''
      store.state.patients.emergencyContactsList = ''
      store.state.patients.timeLineType = ''
      store.state.patients.timeLineType = ''
      store.state.patients.latestVital = []
      store.state.patients.latestCriticalNote = []
    })

    const conferenceId = computed(() => {
			return store.state.videoCall.conferenceId
		})

    const startCallForm = reactive({
      flag: "d76ad323-cd1b-4bcf-ae3d-2300daa1ea17",
      note: "Call",
      patientId: route.params.udid,
    })
    const form = reactive({ ...startCallForm })

    const startCall = () => {
      store.commit('showStartTimer', true);
      iconLoading.value = true
      videoModal.value = true
      store.commit('loadingStatus', true)
      store.dispatch("appointmentCalls", startCallForm).then(() => {
        iconLoading.value = false
        // if(response==true && conferenceId.value) {
        //   store.commit('loadingStatus', false)
        //   let redirect = router.resolve({name: 'videoCall', params: {id: enCodeStringVideoURL(conferenceId.value)}});
        //   window.location.href = redirect.href;
        // }
      })

      
     
    }

    const conversationWithPatient = computed(() => {
      return store.state.communications.conversationWithPatient
    })

    const conversation = computed(() => {
      return store.state.communications.conversationsList
    })
    const conversationId = ref(null)
    
    const sendMessage = () => {
      conversationId.value = conversationWithPatient.value.id
      store.dispatch("conversation", conversationId.value).then(() => {
        chatWithPatientInfoVisible.value = true
      })
    }
    const handleOk = (event) =>{
      
       
       if(!event){
        showDiv.value = false
       }
       visible.value = event
       let link = localStorage.getItem('cancelButton')
       if(link && !event){
          router.push({
            path: link
            });
        }
       
      }

    return {
      handleOk,
      videoModal,
      patientDetails,
      form,
      startCallForm,
      clearEvent,
      screensPermissions,
      arrayToObjact,
      enCodeStringVideoURL,
      conferenceId,
      videoCall,
      startCall,
      sendMessage,
      paramsId:route.params.udid,
      actionTrack,
      stopTimer,
      formattedElapsedTime,
      isEditTimeLog,
      startTimer,
      handleOkcustom,
      custom,
      next,
      prev,
      handleChange,
      notevisible,
      devicevisible,
      documentvisible,
      addnotesvisible,
      timelogsvisible,
      bloodoxygenvisible,
      bloodglucosevisible,
      patientCriticalNotes,
      chatWithPatientInfoVisible,
      conversation,
      conversationId,
      conversationWithPatient,
      receiverId,
      selectedRow,
      visible,
      handleClose,
      onChange: (pagination, filters, sorter, extra) => {
        console.log("params", pagination, filters, sorter, extra);
      },
    
      value1: ref(),
      size: ref("large"),
      value3: ref([]),
      value,
      dayjs,
      onClose,
      button,
      showButton,
      loader,
      iconLoading,
      showStartTimer,
      showPauseTimer,
      showResumeTimer,
      pauseTimer,
      auditTimeLog,
      showDiv,
    };  
  },

  beforeRouteLeave (to, from, next) {
    localStorage.setItem('cancelButton', to.fullPath)
    var button= document.getElementById("timer")
    var formSubmit= document.getElementById("timer3")
    console.log("testt",button)
    if(button) {
      formSubmit ? formSubmit.click() :''
    }
    else {
      next()
    }
  }
  
};
</script>

<style lang="scss">
.timer {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1199px) {
    margin: 0 0 20px;
  }
  h3 {
    margin: 0 10px 0 0;
  }
  .primaryBtn {
    font-size: 12px;
    padding: 6px 10px;
    &:first-of-type{
      margin-right: 5px;
    }
  }
}
.blueBtn {
  position: relative;
  top: 0;
}
</style>
